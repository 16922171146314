import React from 'react'
import {
  Container,
  Label,
  Row,
  Column,
  InfoTable,
  InfoHeader,
  Header,
  SubHeader,
  ContentContainer,
  InfoBody,
  TableColumnName,
  TableColumnValue,
  HeaderContent,
  Name,
  Value,
  InfoBodyContent,
  InfoBodyContentRow,
  LogoWrapper
} from './frequency.styled'

import duImage from './assets/du.svg'
import etisalatImage from './assets/etisalat.svg'
import saudiFlag from './assets/saudi-flag.png'
import stcImage from './assets/stc.png'
import uaeFlag from './assets/uae-flag.png'
import osnImage from './assets/osn.svg'

const Frequency = () => {
  return (
    <>
      <Container>
        <Label>{`ترددات القناة`}</Label>
        <ContentContainer>
          <Row showMarginBottom>
            <Column>
              <div>
                <InfoHeader>
                  <Header>{` نايل سات HD`}</Header>
                  <SubHeader>{`تغطية: الشرق الأوسط وشمال أفريقيا`}</SubHeader>
                </InfoHeader>
                <InfoBody>
                  <InfoTable>
                    <tbody>
                      <tr>
                        <TableColumnName>{`القمر`}</TableColumnName>
                        <TableColumnValue>Eutelsat 7WA</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`تعديل`}</TableColumnName>
                        <TableColumnValue>DVBS2-8PSK</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`التردد`}</TableColumnName>
                        <TableColumnValue>11373</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`الاستقطاب`}</TableColumnName>
                        <TableColumnValue>{`أفقي | Horizontal`}</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`معدل الترميز`}</TableColumnName>
                        <TableColumnValue>27500</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`معدل الخطأ`}</TableColumnName>
                        <TableColumnValue>5/6</TableColumnValue>
                      </tr>
                    </tbody>
                  </InfoTable>
                </InfoBody>
              </div>
            </Column>
            <Column>
              <div>
                <InfoHeader>
                  <Header>نايل سات SD</Header>
                  <SubHeader>{`تغطية: الشرق الأوسط وشمال أفريقيا`}</SubHeader>
                </InfoHeader>
                <InfoBody>
                  <InfoTable>
                    <tbody>
                      <tr>
                        <TableColumnName>{`القمر`}</TableColumnName>
                        <TableColumnValue>Nilesat 201</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`تعديل`}</TableColumnName>
                        <TableColumnValue>DVBS</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`التردد`}</TableColumnName>
                        <TableColumnValue>12226</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`الاستقطاب`}</TableColumnName>
                        <TableColumnValue>{`أفقي | Horizontal`}</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`معدل الترميز`}</TableColumnName>
                        <TableColumnValue>27500</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`معدل الخطأ`}</TableColumnName>
                        <TableColumnValue>5/6</TableColumnValue>
                      </tr>
                    </tbody>
                  </InfoTable>
                </InfoBody>
              </div>
            </Column>
          </Row>
          <Row>
            <Column>
              <div>
                <InfoHeader>
                  <Header>{`عرب سات HD`}</Header>
                  <SubHeader>{`تغطية: الشرق الأوسط وشمال أفريقيا`}</SubHeader>
                </InfoHeader>
                <InfoBody>
                  <InfoTable>
                    <tbody>
                      <tr>
                        <TableColumnName>{`القمر`}</TableColumnName>
                        <TableColumnValue>{'عربسات بدر - 6'}</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`تعديل`}</TableColumnName>
                        <TableColumnValue>DVBS</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`التردد`}</TableColumnName>
                        <TableColumnValue>11862</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`الاستقطاب`}</TableColumnName>
                        <TableColumnValue>{`عمودي | Vertical`}</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`معدل الترميز`}</TableColumnName>
                        <TableColumnValue>27500</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`معدل الخطأ`}</TableColumnName>
                        <TableColumnValue>3/4</TableColumnValue>
                      </tr>
                    </tbody>
                  </InfoTable>
                </InfoBody>
              </div>
            </Column>
            <Column>
              <div>
                <InfoHeader>
                  <Header>{`هوت بيرد HD`}</Header>
                  <SubHeader>{`تغطية: أوروبا والشرق الأوسط وشمال أفريقيا`}</SubHeader>
                </InfoHeader>
                <InfoBody>
                  <InfoTable>
                    <tbody>
                      <tr>
                        <TableColumnName>{`القمر`}</TableColumnName>
                        <TableColumnValue>{'Eutelsat 13C'}</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`تعديل`}</TableColumnName>
                        <TableColumnValue>DVBS2</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`التردد`}</TableColumnName>
                        <TableColumnValue>11747</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`الاستقطاب`}</TableColumnName>
                        <TableColumnValue>{` أفقي | Horizontal`}</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`معدل الترميز`}</TableColumnName>
                        <TableColumnValue>27500</TableColumnValue>
                      </tr>
                      <tr>
                        <TableColumnName>{`معدل الخطأ`}</TableColumnName>
                        <TableColumnValue>3/4</TableColumnValue>
                      </tr>
                    </tbody>
                  </InfoTable>
                </InfoBody>
              </div>
            </Column>
          </Row>
        </ContentContainer>
      </Container>
      <Container>
        <Label>{`أرقام القناة عبر مزودي خدمة التلفزيون`}</Label>
        <ContentContainer>
          <>
            <InfoHeader>
              <Header>
                <HeaderContent>
                  <img
                    src={saudiFlag}
                    alt="saudi arabia flag"
                    style={{
                      width: 57,
                      height: 38,
                      marginLeft: 17
                    }}
                    loading="lazy"
                  />
                  {`السعودية`}
                </HeaderContent>
              </Header>
            </InfoHeader>
            <InfoBody>
              <InfoBodyContent>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <img
                    src={stcImage}
                    alt="stc"
                    style={{
                      width: 83,
                      height: 41,
                      marginLeft: 17
                    }}
                    loading="lazy"
                  />
                  <Name>{`رقم القناة -`}</Name>
                  <Value>{`255`}</Value>
                </div>
              </InfoBodyContent>
            </InfoBody>
          </>
          <>
            <InfoHeader>
              <Header>
                <HeaderContent>
                  <img
                    src={uaeFlag}
                    alt="uae flag"
                    style={{
                      width: 57,
                      height: 38,
                      marginLeft: 17
                    }}
                    loading="lazy"
                  />
                  {`الإمارات العربية المتحدة`}
                </HeaderContent>
              </Header>
            </InfoHeader>
            <InfoBody>
              <InfoBodyContent>
                <InfoBodyContentRow>
                  <LogoWrapper>
                    <img
                      src={duImage}
                      alt="du"
                      style={{
                        width: 59,
                        height: 46,
                        marginLeft: 17
                      }}
                      loading="lazy"
                    />
                  </LogoWrapper>
                  <Name>{`رقم القناة -`}</Name>
                  <Value>{`22`}</Value>
                </InfoBodyContentRow>
                <InfoBodyContentRow>
                  <LogoWrapper>
                    <img
                      src={etisalatImage}
                      alt="etisalat"
                      style={{
                        width: 50,
                        height: 61,
                        marginLeft: 17
                      }}
                      loading="lazy"
                    />
                  </LogoWrapper>
                  <Name>{`رقم القناة -`}</Name>
                  <Value>{`507`}</Value>
                </InfoBodyContentRow>
                <InfoBodyContentRow>
                  <LogoWrapper>
                    <img
                      src={osnImage}
                      alt="OSN"
                      style={{
                        width: 76,
                        height: 49,
                        marginLeft: 17
                      }}
                      loading="lazy"
                    />
                  </LogoWrapper>
                  <Name>{`رقم القناة -`}</Name>
                  <Value>{`414`}</Value>
                </InfoBodyContentRow>
              </InfoBodyContent>
            </InfoBody>
          </>
        </ContentContainer>
      </Container>
    </>
  )
}

export default Frequency

import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import loadable from '@loadable/component'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Frequency from '../components/frequency'

const Footer = loadable(() => import('../components/footer/footer'))

const TuneInPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const seoTitle = 'ترددات تلفزيون الشرق | الشرق للأخبار'
  const seoDescription =
    'تعرف على ترددات تلفزيون الشرق على العرب سات والنايل سات وشاهد بث مباشر لأحدث وأهم الأخبار وأبرز الأحداث التي تجري في منطقة الشرق الأوسط وحول العالم.'

  useEffect(() => {
    navigate('/platforms/')
  }, [])

  return (
    <Layout location={location} title={siteTitle} lang={'ar'}>
      <SEO title={seoTitle} lang="ar" description={seoDescription} />
      <Frequency />
      <Footer />
    </Layout>
  )
}

export default TuneInPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const Container = styled.div`
  background-color: #ffffff;
  margin-top: 30px;
  padding-bottom: 60px;
  margin-bottom: 70px;

  @media ${device.s} {
    margin-top: 75px;
    padding-bottom: 22px;
    margin-bottom: 20px;
  }
`
export const Label = styled.div`
  @media ${device.s} {
    font-size: 18px;
    padding: 0 10px;
    top: -29px;
  }
  padding: 0 40px;
  display: inline;
  height: 44px;
  position: relative;
  z-index: 99;
  top: -34px;
  background-color: #c62326;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0 18px 0 0px;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.5em;
  text-align: center;
  color: #ffffff;
  font-family: 'Neue Haas Grotesk Bloomberg';
  vertical-align: middle;
  line-height: 24px;
  right: 20px;
`

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: ${({ showMarginBottom }) => (showMarginBottom ? '60px' : '0')};

  @media ${device.s} {
    flex-direction: column;
    margin-bottom: 0px;
  }
`

export const Column = styled.div`
  width: 50%;

  @media ${device.s} {
    width: 100% !important;
  }
`

export const InfoTable = styled.table`
  @media ${device.s} {
    height: 259px;
  }

  @media ${device.xl} {
    height: 259px;
    width: 520px;
  }

  width: 100%;
  height: 345px;
  background: rgb(250, 250, 250);
  border-radius: 3px;
  border: 1px solid rgb(238, 238, 238);

  tr:not(:last-child) td {
    border-bottom: 1px solid rgb(240, 240, 240);
  }
`

export const InfoHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`

export const Header = styled.span`
  color: rgb(51, 51, 51);
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  direction: rtl;
`

export const SubHeader = styled.span`
  color: rgb(0, 0, 0);
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 16px;
`

export const ContentContainer = styled.div`
  margin-right: 50px;

  @media ${device.s} {
    margin-right: 20px;
  }
`
export const InfoBody = styled.div`
  padding-left: 20px;
`
export const TableColumnName = styled.td`
  color: rgb(51, 51, 51);
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 18px;
  padding-right: 45px;

  @media ${device.s} {
    font-size: 16px;
    padding-right: 26px;
  }
`

export const TableColumnValue = styled.td`
  color: rgb(51, 51, 51);
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-size: 18px;
  font-weight: bold;
  padding-right: 45px;

  @media ${device.s} {
    font-size: 16px;
    padding-right: 0px;
  }
`
export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
`

export const InfoBodyContent = styled.div`
  background-color: rgb(250, 250, 250);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 48px 34px 48px 0;
  font-family: 'Neue Haas Grotesk Bloomberg';

  @media ${device.s} {
    flex-direction: column;
    padding: 35px 20px 35px 0;
  }

  @media ${device.m} {
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 48px 34px 48px 0;
  }
`

export const Name = styled.span`
  font-weight: 400;
  color: rgb(51, 51, 51);
  font-size: 20px;
  white-space: nowrap;
`

export const Value = styled.span`
  font-weight: bold;
  color: rgb(51, 51, 51);
  font-size: 20px;
  padding-right: 5px;
  white-space: nowrap;
`

export const InfoBodyContentRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 100px;

  @media ${device.s} {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }

  @media ${device.m} {
    margin-bottom: 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const LogoWrapper = styled.span`
  min-width: 76px;
  margin-left: 5px;
`
